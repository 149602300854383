export default {
  apiUrl: 'https://getsport.tech:1337/parse',
};

const siteConfig = {
  siteName: 'ADMIN GSO',
  siteIcon: 'ion-flash',
  footerText: 'ADMIN ©2022',
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

const language = 'english';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

export { siteConfig, language, themeConfig, jwtConfig };
