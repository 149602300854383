const options = [
  {
    key: '',
    label: 'บอร์ดบริหาร',
    leftIcon: 'ion-arrow-graph-up-right',
  },
  {
    key: 'customer',
    label: 'รายชื่อสมาชิก',
    leftIcon: 'ion-android-person',
  },
  {
    key: 'AdminUser',
    label: 'ผู้ใช้ระบบ',
    leftIcon: 'ion-android-person',
  },
  {
    key: 'Payment',
    label: 'รายการแจ้งชำระเงิน',
    leftIcon: 'ion-cash',
  },
  {
    key: 'RegisterMember',
    label: 'รายชื่อผู้สมัครกิจกรรม',
    leftIcon: 'ion-person-add',
  },
  // {
  //   key: 'withdraw',
  //   label: 'รายการแจ้งชำระ',
  //   leftIcon: 'ion-ios-undo',
  //   children: [
  //     {
  //       key: "withdraw",
  //       label: "ข้อมูลทั้งหมด",
  //     },{
  //       key: "waiting-withdraw",
  //       label: "รอตรวจสอบ",
  //     },{
  //       key: "completed-withdraw",
  //       label: "ตรวจสอบแล้ว",
  //     },{
  //       key: "rejected-withdraw",
  //       label: "รายการที่ยกเลิก",
  //     }
  //   ]
  // },
  // {
  //   key: 'bank',
  //   label: 'ธนาคาร',
  //   leftIcon: 'ion-cash',
  //   children: [
  //     {
  //       key: "bank",
  //       label: "ข้อมูลทั้งหมด",
  //     },
  //     {
  //       key: "setting-bank",
  //       label: "ตั้งค่าระบบฝากถอน",
  //     }
  //   ]
  // },
];
export default options;
