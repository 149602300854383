import Parse from "parse";
import config from "../config";
import axios from "axios";
import moment from "moment";
const PARSE_SERVER_URL = config.parseServerUrl
const PARSE_APP_ID = config.appId
const PARSE_JAVASCRIPT_KEY = config.javascriptKey
const PARSE_MASTER_KEY = config.masterKey
const API_URL = config.parseServerUrl.replace('/parse', '/api/v1');

Array.prototype.sumByKey = function (prop) {
    let sum = this.map(o => prop.split('.').reduce((r, val) => { return r ? r[val] : undefined; }, o)).reduce((a, c) => { return a + c }, 0);
    return sum
}

export const init = async () => {
    Parse.initialize(PARSE_APP_ID, PARSE_JAVASCRIPT_KEY, PARSE_MASTER_KEY);
    Parse.masterKey = PARSE_MASTER_KEY
    Parse.serverURL = PARSE_SERVER_URL
};

export const login = async userInfo => {
    init()
    if (!userInfo.username || !userInfo.password) {
        return {
            error: 'please fill in the input'
        };
    }
    const user = await Parse
        .User
        .logIn(userInfo.username, userInfo.password);
    if (user) {

        await user.fetch();
        //console.log('user', user);
        const adminLevel = user.get('adminLevel');
        //console.log('adminLevel', adminLevel);
        if (adminLevel !== undefined || adminLevel !== null) {
            var expireTime = Math.round((new Date()).getTime() / 1000);
            expireTime += 43200
            user.set('lastLogin', new Date());
            user.set('expireTime', expireTime);
            await user
                .save()
                .catch(error => {
                    return {
                        error: error
                    }
                });
            return user
        } else {
            await Parse.User.logOut();
            return {
                error: 'You are not Admin'
            }
        }
    }
    return {
        error: 'Log in failed'
    }
}
export const closeConnection = async () => {
    init()
    Parse
        .LiveQuery
        .close();
    return
}
export const getAllObjects = async (className, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit || 99999)
    query.skip(skip || 0)
    query.descending("createdAt");
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}

export const getObjectsByPagination = async (req) => {
    init()
    const { className, pageSize, pageIndex, queryKey, searchValue, dateFilter, isTrash, queryStatus } = req
    const skip = (pageSize * pageIndex) - pageSize
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.descending("createdAt");
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter && dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (searchValue !== '') {
        query.contains(queryKey, searchValue)
    }
    if (queryStatus) {
        query.equalTo('status', queryStatus)
    }
    const total = await query.count();
    query.limit(pageSize)
    query.skip(skip)
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return { data: objs, total }
}

export const getAllObjectsByDateFilter = async (className, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    query.descending("createdAt");
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}

export const getObjectsCount = async (className, limit, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    if (limit) {
        query.limit(limit)
    }
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.count();

    //console.log(`results length:${results}`)
    return results
}

export const queryObjects = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    ////console.log(`results length:`, results)
    return objs
}

export const queryCompressObjects = async (className, data) => {
    const { key, value, limit, skip, dateFilter, isTrash, selects } = data
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(999999)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value && value !== "") {
        query.contains(key, value);
    }
    if (selects) {
        query.select(selects);
    }
    query.descending("createdAt");
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    ////console.log(`results length:`, results)
    return { results: objs }
}

export const queryObjectsCount = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    return count
}

export const queryObjectsCountByKey = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    // query.limit(limit)
    // query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        //query.contains(key, value);
        query.equalTo(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    return count
}

export const queryObjectsCountBy2Key = async (className, key1, value1, key2, value2, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    // query.limit(limit)
    // query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value1 !== "") {
        query.contains(key1, value1);
    }
    if (value2 !== "") {
        query.contains(key2, value2);
    }
    query.descending("createdAt");
    const count = await query.count();
    return count
}

export const queryObjectsByUpdatedAt = async (className, dateFilter, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.greaterThanOrEqualTo('updatedAt', new Date(dateFilter[0].startOf('day').toString()));
    query.lessThan('updatedAt', new Date(dateFilter[1].endOf('day').toString()));

    //const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${results}`)
    return { results: objs, count: objs.length }
}


export const trashObjectWithId = async (className, objectId, trashBool) => {
    init()
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)
    if (result) {
        if (trashBool) {
            result.set('isTrash', trashBool)
        } else {
            result.unset('isTrash')
        }

        const res = await result.save().catch(error => {
            return { type: 'error', msg: error.message }
        })
        return { type: 'success', msg: 'Done!' }
    } else {
        return { type: 'error', msg: `Can\'t find ${className} with this ID` }
    }
}

export const getObjectWithId = async (className, objectId) => {
    init()
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId, { useMasterKey: true })

    //console.log(`getObjectWithId :${JSON.stringify(result)}`)
    return result
}
export const destroyObjectWithId = async (className, objectId) => {
    init()

    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)
    try {
        const res = await result.destroy({ useMasterKey: true })
        return { type: 'success', msg: 'Destroy Done!' }

    } catch (error) {
        return { type: 'error', msg: 'Destroy Error! :' + error.message }
    }
}
export const updatePaymentStatusWithId = async (className, objectId, status) => {
    init()
    //console.log(`editObject:${JSON.stringify(req)}`)
    const query = new Parse.Query(Parse.User);
    //const query = Parse.Object.extend(className);
    const user = await query.get(objectId)
    user.set('paymentStatus', status);
    await user.save(null, { useMasterKey: true }).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })

    return { type: 'success', msg: 'Done!' }
}

export const createUser = async (className, req) => {
    init()
    //console.log(`createObject:${JSON.stringify(req)}`)

    const ClassName = Parse.Object.extend(className);
    const obj = new ClassName();
    try {
        //const res = await obj.save(req, { useMasterKey: true })
        const res = await obj.signUp(req, { useMasterKey: true });
        const acl = new Parse.ACL();
        acl.setPublicReadAccess(true);
        acl.setPublicWriteAccess(true);
        acl.setWriteAccess(Parse.User.current(), true);
        res.setACL(acl);
        await res.save(null, { useMasterKey: true })
        return { type: 'success', msg: 'Done!', objectId: res.id, object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message + ' code: ' + error.code, code: error.code }
    }
}

export const createObject = async (className, req) => {
    init()
    //console.log(`createObject:${JSON.stringify(req)}`)
    const ClassName = Parse.Object.extend(className);
    const obj = new ClassName();
    try {
        const res = await obj.save(req, { useMasterKey: true })
        const acl = new Parse.ACL();
        acl.setPublicReadAccess(true);
        acl.setPublicWriteAccess(true);
        acl.setWriteAccess(Parse.User.current(), true);
        res.setACL(acl);
        await res.save(null, { useMasterKey: true })
        return { type: 'success', msg: 'Done!', objectId: res.id, object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message + ' code: ' + error.code, code: error.code }
    }
}

export const updateUser = async (req) => {
    init()
    //console.log(`editObject:${JSON.stringify(req)}`)
    const query = new Parse.Query(Parse.User);
    const user = await query.get(req.objectId)
    await user.save(req, { useMasterKey: true }).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const editObject = async (req) => {
    init()
    console.log(`editObject:${JSON.stringify(req)}`)
    const res = await req.save(null, { useMasterKey: true }).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })

    console.log('save res is:', res);
    return { type: 'success', msg: 'Done!' }
}

export const saveAllObject = async req => {
    init()
    //console.log(`saveAllObject req`, req)
    const res = await Parse.Object.saveAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}
export const fetchAllObject = async req => {
    init()
    //console.log(`saveAllObject req`, req)
    const res = await Parse.Object.fetchAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return res
}
export const destroyAllObject = async req => {
    init()
    const res = await Parse.Object.destroyAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const uploadParseFile = async (file, isUseOriginalFileName) => {
    init()
    const uuid = new Date().getTime()
    //console.log(`upload uuid :  ${uuid}`)
    const extend = !isUseOriginalFileName ? file.type === 'image/jpeg' ? 'jpg' : 'png' : file.name.split('.').pop();
    var parseFile = new Parse.File(isUseOriginalFileName ? file.name.replace(/ /g, "-").replace(/\(/g, "").replace(/\)/g, "") : `${uuid}.${extend}`, file.originFileObj);
    try {
        const img = await parseFile.save();
        //console.log(`upload img.url :  ${img.url()}`)
        const File = Parse.Object.extend("File")
        const obj = new File()
        obj.set('file', img)
        obj.save()
        return { url: img.url(), img: img }
    } catch (error) {
        //console.log(`upload error :  ${error.message}`)

        return { type: 'error', msg: 'Error! :' + error.message }

    }
}

export const uploadParseThumbnailFile = async file => {
    init()
    const uuid = new Date().getTime()
    //console.log(`upload uuid :  ${uuid}`)
    var parseFile = new Parse.File(`${uuid}.jpg`, { base64: file });
    const img = await parseFile.save().catch(error => {
        //console.log(`upload error :  ${error.message}`)

        return { type: 'error', msg: 'Error! :' + error.message }
    })
    //console.log(`upload img.url :  ${img.url()}`)
    const File = Parse.Object.extend("Thumbnail")
    const obj = new File()
    obj.set('file', img)
    obj.save()
    return { url: img.url(), img: img }

}

export const queryObjectBySomeKey = async (className, key, value, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.equalTo(key, value);
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const getValueById = async (className, key, objectId) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    return result.get(key)
}

export const resetPassword = async (objectId, password) => {
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    const user = await query.get(objectId);
    user.set('password', password)
    try {
        await user.save()
        return ({ type: 'success', msg: 'เรียบร้อย' })
    } catch (error) {
        return ({ type: 'error', msg: error.message })
    }
}

export const queryObjects2 = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('fromDate', dateFilter[0].startOf('day').toDate());
        query.lessThanOrEqualTo('toDate', dateFilter[1].endOf('day').toDate());
    }
    if (value !== "") {
        query.equalTo(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const getConfig = async () => {
    init()
    const config = await Parse.Config.get({ useMasterKey: true })
    return config
}
export const saveConfig = async (data) => {
    init()
    //console.log('saveConfig', data)
    const res = await Parse.Config.save(data, { useMasterKey: true }).catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!", config: res }

}

export const getCurrentUser = async () => {
    init()
    const user = await Parse.User.current();
    await user.fetch({ useMasterKey: true });
    return user
}



export const adminLogger = async (type, activity, data) => {
    init()
    const user = await getCurrentUser()
    const Activity = Parse.Object.extend('Activity');
    const obj = new Activity()
    obj.set('user', user)
    obj.set('username', user.get('username'))
    obj.set('type', type)
    obj.set('activity', activity)
    if (data) {
        obj.set('data', data)
    }
    try {
        const res = await obj.save()
        return { type: 'success', msg: 'Ok', object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message }
    }
}

export const checkCustomerRegistered = async (lineId) => {
    init()
    const ClassName = Parse.Object.extend('Customer');
    const query = new Parse.Query(ClassName);
    query.equalTo('lineId', lineId)
    const result = await query.first();
    const contactName = result.get('contactName')
    return contactName ? result : false
}

export const registerCustomer = async (lineId, data) => {
    init()
    const ClassName = Parse.Object.extend('Customer');
    const query = new Parse.Query(ClassName);
    query.equalTo('lineId', lineId)
    const result = await query.first();
    if (result) {
        const res = await result.save(data)
        return res.error ? 'การลงทะเบียนมีปัญหา' : 'success'
    } else {
        return 'ไม่พบผู้ใช้'
    }
}

export const getCustomerByLineId = async (lineId) => {
    init()
    const ClassName = Parse.Object.extend('Customer');
    const query = new Parse.Query(ClassName);
    query.equalTo('lineId', lineId)
    const result = await query.first();
    return result
}

export const getCustomerByEmail = async (email) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    query.equalTo('email', email)
    const result = await query.first();
    return result
}

export const getCustomerByID = async (objectId) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    query.equalTo('objectId', objectId)
    const result = await query.first();
    console.log('result', result);
    return result
}

export const countCustomerByAdminId = async (adminId) => {
    init()
    const ClassName = Parse.Object.extend('Customer');
    const query = new Parse.Query(ClassName);
    query.equalTo('adminId', adminId)
    const result = await query.count();
    return result
}

export const getCustomerByAdminId = async (adminId) => {
    init()
    const ClassName = Parse.Object.extend('Customer');
    const query = new Parse.Query(ClassName);
    query.equalTo('adminId', adminId)
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    return objs
}

export const getBillsByCustomerIdAndRoundDate = async (customerId, roundDate) => {
    init()
    const ClassName = Parse.Object.extend('Bill');
    const query = new Parse.Query(ClassName);
    query.equalTo('customerId', customerId)
    query.equalTo('roundDate', roundDate)
    query.doesNotExist('isTrash')
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    return objs
}

export const getNumberbyBillId = async (billId) => {
    init()
    const ClassName = Parse.Object.extend('Number');
    const query = new Parse.Query(ClassName);
    query.equalTo('billId', billId)
    query.doesNotExist('isTrash')
    query.limit(99999)
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    return objs
}

export const saveNumbers = async (numbers) => {
    init()
    const admin = await getCurrentUser();
    const ClassName = Parse.Object.extend('Number');
    const map = numbers.map(item => {
        if (!item.objectId) {
            const num = new ClassName();
            num.set('rawNum', item.rawNum);
            num.set('amount', item.amount);
            num.set('type', item.type);
            num.set('digit', item.digit);
            num.set('customerId', item.customerId);
            num.set('billId', item.billId);
            num.set('roundDate', item.roundDate);
            num.set('discount', item.discount);
            num.set('admin', admin);
            return num;
        }
    })
    //console.log('map', map);
    const res = await Parse.Object.saveAll(map)
    return res
}

export const getAllNumberbyRoundDate = async (roundDate) => {
    init()
    const ClassName = Parse.Object.extend('Number');
    const query = new Parse.Query(ClassName);
    query.equalTo('roundDate', roundDate)
    query.doesNotExist('isTrash')
    query.limit(999999)
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        objs.push(json)
    })
    return objs
}


export const getAdmins = async (isTrash) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    query.descending("createdAt");
    query.exists('adminLevel');
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}


export const getCustomers = async (data) => {
    const { isTrash, selects } = data || {}
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    query.descending("createdAt");
    query.doesNotExist('adminLevel');
    query.limit(99999);
    query.skip(0);
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (selects) {
        query.select(selects)
    }
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    return objs
}
export const listenTransaction = async (className) => {
    let query = new Parse.Query(className);
    let subscription = await query.subscribe();
    return subscription

}
export const listenRebate = async () => {
    let query = new Parse.Query('Rebate');
    query.equalTo('status', 'pending');
    let subscription = await query.subscribe();
    return subscription

}

export const addCustomerBalance = async (userId, amount) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    const user = await query.get(userId);
    if (user) {
        try {
            await user.fetch();
            const oldBalance = user.get('balance');
            user.increment('balance', amount);
            const res = await user.save(null, { useMasterKey: true });
            return { type: 'success', msg: "Save done!", data: { userId, oldBalance, addAmount: amount } }
        } catch (error) {
            return { type: 'error', msg: error.message }
        }
    } else {
        return { type: 'error', msg: "Not found user" }
    }
}

export const changePassword = async (userId, password) => {
    if (passwordValidation(password)) {
        const data = {
            objectId: userId,
            password
        }
        const res = await axios.post(`${API_URL}/user/resetPassword`, data);
        if (res.data.status === 'success') {
            return { type: 'success', msg: "Save done!", data: { userId, password } }
        } else {
            return { type: 'error', msg: res.data.message }
        }
    } else {
        return { type: 'error', msg: "Password not meet the requierment" }
    }
}

const passwordValidation = (value) => {
    var reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.{8,})");
    if (!reg.test(value)) {
        return false
    } else {
        return true
    }
}

export const approveWithdraw = async (objectId) => {
    init()
    const query = new Parse.Query('Withdraw');
    const user = await Parse.User.current();
    const wd = await query.get(objectId);
    if (wd) {
        try {
            wd.set('status', 'completed');
            wd.set('admin', user);
            wd.set('adminId', user.id);
            wd.set('transferMethod', 'manual');
            let logs = wd.get('logs') || {};
            logs.completed = Date.now();
            wd.set('logs', logs);
            await wd.save();
            return { type: 'success', msg: "Save done!", data: { objectId, status: 'completed' } }
        } catch (error) {
            return { type: 'error', msg: error.message }
        }
    } else {
        return { type: 'error', msg: "Not found data" }
    }
}

export const rejectWithdraw = async (objectId) => {
    init()
    const query = new Parse.Query('Withdraw');
    const admin = await Parse.User.current();
    const wd = await query.get(objectId);
    if (wd) {
        try {
            wd.set('status', 'rejected');
            wd.set('admin', admin);
            wd.set('adminId', admin.id);
            let logs = wd.get('logs') || {};
            logs.rejected = Date.now();
            wd.set('logs', logs);
            await wd.save();
            const amount = wd.get('amount');
            const user = wd.get('user');
            await user.fetch();
            user.increment('balance', amount);
            await user.save(null, { useMasterKey: true });
            return { type: 'success', msg: "Reject done!", data: { objectId, status: 'rejected' } }
        } catch (error) {
            return { type: 'error', msg: error.message }
        }
    } else {
        return { type: 'error', msg: "Not found data" }
    }
}

export const createDeposit = async (userId, amount, dateTime, targetBank, slipUrl, isCheckMinDiff) => {
    init()
    try {
        const admin = await Parse.User.current();
        const query = new Parse.Query('User');
        const user = await query.get(userId);
        await user.fetch();
        const bankCode = user.get('bankCode');
        const bankAccountNumber = user.get('bankAccountNumber');
        const bankAccNum = bankCode === '014' && targetBank === 'SCB' ? bankAccountNumber.substr(bankAccountNumber.length - 4) : bankAccountNumber.substr(bankAccountNumber.length - 6)
        const topUpDestination = user.get('topUpDestination');
        const checkStr = `${dateTime.format('DD/MM/YYYY')},${dateTime.format('HH:mm')},${amount},${bankCode},${bankAccNum}`
        //console.log('checkStr', checkStr);
        const ClassName = Parse.Object.extend('Deposit');
        const dQuery = new Parse.Query('Deposit');
        dQuery.equalTo('checkStr', checkStr);
        const dup = await dQuery.first();
        if (dup) {
            //console.log('duplicate deposit', dup);
            return { type: 'error', msg: 'Duplicate Deposit' }
        }
        if (isCheckMinDiff) {
            const dQuery2 = new Parse.Query('Deposit');
            dQuery2.equalTo('userId', user.id);
            dQuery2.descending('createdAt');
            const checkDeposit = await dQuery2.first();
            if (checkDeposit) {
                const timeParsed = checkDeposit.get('checkStr').split(',');
                const previousAmount = checkDeposit.get('amount')
                const diff = moment(`${dateTime.format('DD/MM/YYYY')} ${dateTime.format('HH:mm')}`, 'DD/MM/YYYY HH:mm').diff(moment(`${timeParsed[0]} ${timeParsed[1]}`, 'DD/MM/YYYY HH:mm'), 'minutes');
                //console.log('deposit diff minute:', Math.abs(diff));
                if (Math.abs(diff) < 2 && amount === previousAmount) {
                    //console.log('deposit reject by minimum time diff and same amount:', checkStr);
                    return { type: 'error', msg: 'Deposit same amount with in 2 min.' }
                }
            }
        }

        const deposit = new ClassName();
        deposit.set('userId', userId);
        deposit.set('user', user);
        deposit.set('amount', amount);
        deposit.set('game', '918');
        deposit.set('bankCode', bankCode);
        deposit.set('targetBank', targetBank);
        deposit.set('bankAccountNumber', bankAccountNumber);
        deposit.set('verifyMethod', 'admin');
        deposit.set('status', 'completed');
        deposit.set('logs', { completed: Date.now() });
        deposit.set('processTime', 1);
        deposit.set('topUpDestination', topUpDestination);
        deposit.set('checkStr', checkStr);
        deposit.set('timeStr', dateTime.format('HH:mm'));
        deposit.set('admin', admin);
        deposit.set('adminId', admin.id);
        if (slipUrl) {
            deposit.set('slipUrl', slipUrl);
        }
        const res = await deposit.save();
        // user.increment('balance', amount);
        // await user.save(null, { useMasterKey: true });
        const topupRes = await axios.post(`${API_URL}/balance/topupFromDeposit`, { depositId: res.id });
        //console.log('topupRes',topupRes.data);
        return { type: 'success', msg: "Create done!", data: { objectId: res.id } }
    } catch (error) {
        return { type: 'error', msg: error.message }
    }
}
export const createDepositByAPI = async (userId, amount, dateTime, targetBank, isCheckMinDiff) => {
    init()
    try {
        const admin = await Parse.User.current();
        const dateStr = dateTime.format('DD/MM/YYYY')
        const timeStr = dateTime.format('HH:mm')
        const data = {
            userId, amount, dateStr, timeStr, targetBank, isCheckMinDiff, adminId: admin.id
        }
        const res = await axios.post(`${API_URL}/deposit/createDeposit`, data);
        console.log('createDepositByAPI', res.data);
        return { type: res.data.status, msg: res.data.msg, requestData: data }
    } catch (error) {
        return { type: 'error', msg: error.message }
    }
}


export const getAllUserBalance = async (dateStr) => {
    init()
    const query = new Parse.Query('BalanceReport');
    query.equalTo('dateStr', dateStr);
    const res = await query.first();
    if (res) {
        return { type: 'success', data: res.toJSON() }
    } else {
        return { type: 'error', msg: "Not found data" }
    }
}

export const getAllUserAPIBalance = async (date) => {
    init()
    if (moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
        const query = new Parse.Query('User');
        query.limit(9999)
        const users = await query.find();
        const balances = users.map(item => item.get('balance'))
        const balance_API = balances.filter(item => !!item).reduce((total, balance) => total + balance, 0)
        return { type: 'success', data: { balance_API, createdAt: new Date() } };
    } else {
        const query = new Parse.Query('BalanceReport');
        query.equalTo('dateStr', date.format('YYYY-MM-DD'));
        const res = await query.first();
        if (res) {
            return { type: 'success', data: res.toJSON() }
        } else {
            return { type: 'error', msg: "Not found data" }
        }
    }

}



export const rejectRebate = async (objectId) => {
    init()
    const query = new Parse.Query('Rebate');
    const admin = await Parse.User.current();
    const wd = await query.get(objectId);
    if (wd) {
        try {
            wd.set('status', 'rejected');
            wd.set('admin', admin);
            wd.set('adminId', admin.id);
            let logs = wd.get('logs') || {};
            logs.rejected = Date.now();
            wd.set('logs', logs);
            await wd.save();
            return { type: 'success', msg: "Reject done!", data: { objectId, status: 'rejected' } }
        } catch (error) {
            return { type: 'error', msg: error.message }
        }
    } else {
        return { type: 'error', msg: "Not found data" }
    }
}

export const redeemRebate = async (objectId) => {
    init()
    const query = new Parse.Query('Rebate');
    const admin = await Parse.User.current();
    const wd = await query.get(objectId);
    if (wd) {
        try {
            wd.set('status', 'completed');
            wd.set('admin', admin);
            wd.set('adminId', admin.id);
            let logs = wd.get('logs') || {};
            logs.completed = Date.now();
            const amount = wd.get('rebate');
            const user = wd.get('user');
            await user.fetch();
            const oldBalance = user.get('balance')
            logs.oldBalance = oldBalance
            logs.amount = amount
            wd.set('logs', logs);
            await wd.save();
            user.increment('balance', amount);
            await user.save(null, { useMasterKey: true });
            return { type: 'success', msg: "Redeem done!", data: { objectId, status: 'completed' } }
        } catch (error) {
            return { type: 'error', msg: error.message }
        }
    } else {
        return { type: 'error', msg: "Not found data" }
    }
}


export const queryRebateByUpdatedAt = async (dateFilter) => {
    init()
    const ClassName = Parse.Object.extend('Rebate');
    const query = new Parse.Query(ClassName);
    query.limit(99999)
    query.skip(0)
    query.equalTo('status', 'completed')
    query.greaterThanOrEqualTo('updatedAt', new Date(dateFilter[0].startOf('day').toString()));
    query.lessThan('updatedAt', new Date(dateFilter[1].endOf('day').toString()));

    //const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${results}`)
    return { results: objs, count: objs.length }
}

export const getReport = async (data) => {
    const { dateFilter } = data
    init()
    const ClassName = Parse.Object.extend('Report');
    const query = new Parse.Query(ClassName);
    query.limit(30)
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('reportDate', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('reportDate', new Date(dateFilter[1].endOf('day').toString()));
    }
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    return objs
}


