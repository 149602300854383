import { Map } from 'immutable';
import moment from 'moment';
import 'moment/locale/th';
moment.locale('th');


export const determineRoundDate = () =>{
  const today = moment();
  if(today.add(25,'d').isAfter(today,'month')){

  }else{
    
  }
}

export const phoneValidation = (value) => {
  const reg = new RegExp('^[0-9]+$');
  if (value.length !== 10) {
    return false
  } else if (value.indexOf('-') > -1) {
    return false
  } else if (!reg.test(value)) {
    return false
  } else {
    return true
  }
}

export const bankAccountValidation = (value) => {
  const reg = new RegExp('^[0-9]+$');
  if (value.indexOf('-') > -1) {
    return false
  } else if (!reg.test(value)) {
    return false
  } else {
    return true
  }
}




export function clearToken() {
  //localStorage.removeItem('id_token');
  localStorage.clear();

}

export function getToken() {
  try {
    const id_token = localStorage.getItem("id_token");
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("user_role");
    const user_id = localStorage.getItem("user_id");
    const expireTime = localStorage.getItem("expireTime");

    return new Map({
      id_token,
      role,
      token,
      user_id,
      expireTime,
    });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}


export function randomFromRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function randomByPercentage(num, percent) {
  const max = num * (100 + percent) / 100
  const min = num * (100 - percent) / 100
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function randomIncreaseByPercentage(num, percent) {
  const max = num * (100 + percent) / 100
  return Math.floor(Math.random() * max);
}

export function randomFillArray(initNum,count){
  let arr = [];
  var temp = initNum
  for(let i = 1; i <= count; i++){
    const next = randomFromRange(1,temp)
    const v = temp - next
    if(i===count){
      arr.push(temp)
    }else{
      arr.push(v)
      temp = next
    }
    
  }
  return arr
}