import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

//เมื่อเพิ่มหน้าใหม่จะต้องเพิ่ม Routes ให้สอดคล้องกันเสมอ
const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'customer',
    component: asyncComponent(() => import('../Customer')),
  },
  {
    path: 'AdminUser',
    component: asyncComponent(() => import('../AdminUser')),
  },
  {
    path: 'Payment',
    component: asyncComponent(() => import('../Payment')),
  },
  {
    path: 'RegisterMember',
    component: asyncComponent(() => import('../RegisterMember')),
  }
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
